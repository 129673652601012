import { useCallback, useState } from 'react';
import api from '../api';

export default function useStores() {
  const [result, setResult] = useState({ data: [], total: 0 });
  const [usersResult, setUsersResult] = useState({ data: [] });
  const [params, setParams] = useState({page: 0, rowsPerPage: 20});
  const [stores, setStores] = useState([]);
  const [message, setMessage] = useState({ message: '', show: false });
  const [isLoading, setIsLoading] = useState(false);
  const [newStore, setNewStore] = useState({});

  const fetchStores = useCallback(async () => {
    try {
      const { data } = await api.get(`/admin/list/stores/`);

      if (data) {
        setResult(data);
      } else {
        setResult({ data: [] });
      }
    } catch {
      setResult({ data: [] });
    }
  }, []);

  const searchDealer = useCallback(async (dataSearch) => {
    setIsLoading(true);

    try {
      const queryParams = {
          params: {
              name: dataSearch[0].name,
              responsiblePerName: dataSearch[1].responsiblePerName,
          },
      };
      const { data } = await api.get(`/admin/list/stores/${params.page}/${dataSearch.size ?? params.rowsPerPage}`, queryParams);
      setResult(data);
    } catch {
        setResult({ data: [], total: 0 });
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  const searchDealerUsers = useCallback(async (dataSearch) => {
    setIsLoading(true);

    try {
      const queryParams = {
          params: {
              name: dataSearch[0].name,
              storeId: dataSearch[1].storeId
          },
      };
      const { data } = await api.get(`/admin/list/stores/users/${params.page}/${dataSearch.size ?? params.rowsPerPage}`, queryParams);
      setUsersResult(data);
    } catch {
      setUsersResult({ data: [] });
    } finally {
      setIsLoading(false);
    }
  })

  const createDealer = useCallback(async (formData) => {
    setIsLoading(true);

    try {
      const { data } = await api.post('/admin/create/dealer', formData);
      setNewStore(data)
      setMessage({ message: 'Criado com sucesso!', show: true, status: 200 })
    } catch {
      setMessage({ message: 'Erro ao criar dealer', show: true, status: 500 })
    } finally {
      setIsLoading(false);
    }
  })

  const updateDealer = useCallback(async (formData) => {
    setIsLoading(true)

    try {
      await api.put('/admin/update/dealer', formData);
      setMessage({ message: 'Atualizado com sucesso!', show: true, status: 200 })
    } catch {
      setMessage({ message: 'Erro ao atualizar dealer', show: true, status: 500 })
    } finally {
      setIsLoading(false)
    }
  })

  return { result, usersResult, params, setParams, stores, message, isLoading, setMessage, fetchStores, searchDealer, searchDealerUsers, createDealer, updateDealer, newStore };
}