import { useCallback, useState } from 'react';
import api from '../api';

export default function useFinancial() {
  const [result, setResult] = useState({ data: [], total: 0 });
  const [params, setParams] = useState({page: 0, rowsPerPage: 20});
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchFinancial = useCallback(async () => {
    try {
      const { data } = await api.get(`/admin/list/financial/`);

      if (data) {
        setResult(data);
      } else {
        setResult({ data: [] });
      }
    } catch {
      setResult({ data: [] });
    }
  }, []);

  const searchFinancial = useCallback(async (dataSearch) => {
    setIsLoading(true);

    try {
        const queryParams = {
            params: {
                createdAtMonth: dataSearch[0].createdAtMonth.value,
                plate: dataSearch[1].plate,
            },
        };
        const { data } = await api.get(`/admin/list/financial/${params.page}/${dataSearch.size ?? params.rowsPerPage}`, queryParams);
        setResult(data);
    } catch {
        setResult({ data: [], total: 0 });
    } finally {
      setIsLoading(false);
    }
  }, [params])

  return { result, params, setParams, message, isLoading, setMessage, fetchFinancial, searchFinancial };
}