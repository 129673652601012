import { useCallback, useState } from "react";
import api from "../api";

export default function useDashboard() {
  const [result, setResult] = useState({ data: { perStore: [] } });
  const [params, setParams] = useState({ page: 0, rowsPerPage: 5 });
  const [dashboard, setDashboard] = useState([]);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchDashboard = useCallback(async () => {
    setIsLoading(true);

    try {
      // RESPONSE
      // Status 0 = createSurvey/pending
      // Status 3 - Cancelados
      // Status 4 - waitOrc
      // Status 5 - Finalizados
      // Survey - Quantos formularios existem
      const response = await api.get(`/admin/dashboard`);

      if (response.status === 200) {
        setResult(response.data);
      }
    } catch {
      // setResult({ data: {perStore: []}, status: 500, message: 'Sem conexão com o servidor!' });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    result,
    params,
    setParams,
    isLoading,
    dashboard,
    message,
    status,
    fetchDashboard,
  };
}
