import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { saveProfilePicInLocalStorage } from '../utils/formatImage';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [userName, setUserName] = useState('');
    const [userMail, setUserMail] = useState('')
    const profilePicName = sessionStorage.getItem('Kinto_admin_profilePic');
    const navigate = useNavigate();

    const updateUserName = useCallback(async () => {
        const token = localStorage.getItem('Token_Kinto_Admin');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setUserName(decoded.name);
                setUserMail(decoded.mail)
                
                if (decoded.pic !== null && !profilePicName) {
                    await saveProfilePicInLocalStorage(`${process.env.REACT_APP_API_PROFILE_PIC}/${decoded.pic}`);
                }
            } catch {
                navigate('/login');
            }
        }
    }, [navigate]);

    useEffect(() => {
        updateUserName();
    }, [updateUserName]);
    
    useEffect(() => {
        window.addEventListener('tokenUpdated', updateUserName);
        return () => {
            window.removeEventListener('tokenUpdated', updateUserName);
        };
    }, [updateUserName]);

    return (
        <AppContext.Provider value={{ userName, profilePicName, userMail }}>
            {children}
        </AppContext.Provider>
    );
};