const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
  }
  
export const getComparator = (order, orderBy) => (order === 'desc' 
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
)
  
export const applySortFilter = (array, comparator) => {
    if (array && array.length > 0) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
      });
        const filteredArray = array;
        const stabilizedFiltered = filteredArray.map((el, index) => [el, index]);
        stabilizedFiltered.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
  
        return stabilizedFiltered.map((el) => el[0]);
    }
    return []; 
}