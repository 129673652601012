import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useSurvey from "../../hooks/Services/useSurvey";
import CancelSurveyModal from "../modals/CancelSurveyModal";
import useResponsive from "../../hooks/useResponsive";

const CardKanban = ({ infoCards, resetFilters }) => {
  const { status } = infoCards;

  const navigate = useNavigate();

  const isMobile = useResponsive("down", "sm");
  const isTablet = useResponsive("between", "sm", "md");

  // primary - 2065d1
  // warning - ffc108
  // success - 5fd03a
  // error - ff4842
  const { setMessage } = useSurvey();

  const [openCancelModal, setOpenCancelModal] = useState(false);

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  return (
    <Box
      sx={{
        width: isMobile ? "50vw" : isTablet ? "30vw" : "100%",
        color: "black",
        borderRadius: "10px",
        padding: "0.5rem",
        display: "flex",
        flexDirection: "column",
        backgroundColor:
          status === 0
            ? "#2064d136"
            : status === 1
            ? "#ffc1083d"
            : status === 5
            ? "#60d03a45"
            : status === 3
            ? "#ff484253"
            : "#ff484253",
        border:
          status === 0
            ? "2px solid #2065d1"
            : status === 1
            ? "2px solid #ffc108"
            : status === 5
            ? "2px solid #5fd03a"
            : status === 3
            ? "2px solid #ff4842"
            : "2px solid #ff4842",
      }}
    >
      <span>Marca: {infoCards?.brand || "-"}</span>
      <span>Modelo: {infoCards?.model || "-"}</span>
      <span>Placa: {infoCards?.plate || "-"}</span>
      <span>Ano: {infoCards?.year || "-"}</span>
      <span>Cod: {infoCards?.cod || "-"}</span>

      <Box
        display="flex"
        gap="0.5rem"
        mt={1}
        flexDirection={isMobile || isTablet ? "column" : "none"}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/dashboard/view-ticket", { state: infoCards });
          }}
          sx={{
            color: "black",
            borderColor: "black",
          }}
        >
          Abrir
        </Button>
        {status <= 3 &&
          (status === 3 ? (
            <Button
              variant="outlined"
              onClick={() => setOpenCancelModal(true)}
              sx={{
                color: "black",
                borderColor: "black",
              }}
            >
              {" "}
              Ativar
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => setOpenCancelModal(true)}
              sx={{
                color: "black",
                borderColor: "black",
              }}
            >
              {" "}
              Rejeitar
            </Button>
          ))}
      </Box>

      <CancelSurveyModal
        openModal={openCancelModal}
        survey={infoCards}
        onClose={handleCloseCancelModal}
        onSubmit={(msg) => {
          resetFilters();
          setMessage(msg);
        }}
      />
    </Box>
  );
};

export default CardKanban;
