import PropTypes from 'prop-types';
import React from "react";
import { Alert, Snackbar, AlertTitle } from "@mui/material";

const AlertSnackbar = ({ openAlert, duration, message, status, onClose }) => (
    <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openAlert}
        autoHideDuration={duration}
        onClose={onClose}
    >
        <Alert severity={status === 0 ? 'error' : status === 500 ? 'error' : status === 400 ? 'error' : status === 404 ? 'error' : 'success'} variant="filled">
            {status !== 0 && (
                <AlertTitle>{status === 500 ? 'Erro' : status === 400 ? 'Erro' : status === 404 ? 'Erro' : 'Sucesso'}</AlertTitle>
            )}
            {message}
        </Alert>
    </Snackbar>
)

AlertSnackbar.propTypes = {
    openAlert: PropTypes.bool,
    duration: PropTypes.number,
    message: PropTypes.string,
    status: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    onClose: PropTypes.func,
};

export default AlertSnackbar;