import { useCallback, useState } from 'react';
import api from '../api';

export default function useUsers() {
  const [result, setResult] = useState({ data: [] });
  const [params, setParams] = useState({page: 0, rowsPerPage: 20});
  const [message, setMessage] = useState({ message: '', show: false });
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await api.get(`/admin/list/users/`);

      if (data) {
        setResult(data);
      } else {
        setResult({ data: [] });
      }
    } catch {
      setResult({ data: [] });
    }
  }, []);

  const searchUsers = useCallback(async (dataSearch) => {
    setIsLoading(true);

    try {
        const queryParams = {
          params: {
              name: dataSearch[0].name,
              email: dataSearch[1].email,
          },
        }
        const { data } = await api.get(`/admin/list/users/${params.page}/${params.rowsPerPage}`, queryParams);
        setResult(data);
    } catch {
        setResult({ data: [] });
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  const createUser = useCallback(async (formData) => {
    setIsLoading(true);

    try {
      const { data } = await api.post('/admin/create/user', formData);

      if (data) {
        setMessage({ message: data.message, show: true, });
      }
    } catch {
      setResult({ data: [] });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const toggleActiveUser = useCallback(async (userId) => {
    setIsLoading(true);

    try {
      const { data } = await api.put('/admin/toggle-active/user', userId);

      if (data) {
        setMessage({ message: data.msg, show: true, });
      }
    } catch {
      console.log('error');
    } finally {
      setIsLoading(false);
    }
  }, [])

  const updateUser = useCallback(async (formData) => {
    setIsLoading(true);

    try {
      await api.put('/admin/update/user', formData);
      setMessage({ message: 'Atualizado com sucesso!', show: true, status: 200 });
    } catch {
      setResult({ data: {}, status: 500, message: 'Sem conexão com o servidor!' });
      setMessage({ message: 'Erro ao atualizar usuário!', show: true, status: 500 });
    } finally {
      setIsLoading(false);
    }
  })

  return { result, params, setParams, message, setMessage, status, fetchUsers, searchUsers, createUser, toggleActiveUser, updateUser, isLoading };
}