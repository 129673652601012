import numeral from 'numeral';

// ----------------------------------------------------------------------

export function formatCNPJ(value) {
  // Remove tudo que não for número usando regex
  let numericValue = value.replace(/\D/g, '');

  // Limita o valor a no máximo 14 dígitos
  const maxLength = 14;
  if (numericValue.length > maxLength) {
    numericValue = numericValue.slice(0, maxLength);
  }

  // Aplica a formatação desejada (##.###.###/####-##)
  if (numericValue.length <= 2) {
    return numericValue;
  }
  if (numericValue.length <= 5) {
    return numericValue.replace(/(\d{2})(\d)/, '$1.$2');
  }
  if (numericValue.length <= 8) {
    return numericValue.replace(/(\d{2})(\d{3})(\d)/, '$1.$2.$3');
  }
  if (numericValue.length <= 12) {
    return numericValue.replace(/(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3/$4');
  }

  return numericValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, '$1.$2.$3/$4-$5');
};

export function formatPhoneWithDDD(value) {
  // Remove tudo que não for número usando regex
  let numericValue = value.replace(/\D/g, '');

  // Limita o valor a no máximo 11 dígitos (2 dígitos para DDD + 9 dígitos para o telefone)
  const maxLength = 11;
  if (numericValue.length > maxLength) {
    numericValue = numericValue.slice(0, maxLength);
  }

  // Aplica a formatação desejada (## #####-####)
  if (numericValue.length <= 2) {
    return numericValue;
  }
  if (numericValue.length <= 7) {
    return numericValue.replace(/(\d{2})(\d{1,5})/, '($1) $2');
  }

  return numericValue.replace(/(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3');
};

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number) {
  const format = numeral(number) ? number.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : '';

  return format;
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fOnlyNumbers(value) {
  let numericValue = value.toString();
  numericValue = numericValue.replace(/[^\d]/g, '');
  const numericValid = numericValue === '' ? '0' : numericValue;

  return numericValid;
}

export function formatCEP(value) {
  const cepLimitado = fOnlyNumbers(value).slice(0, 8);

  if (cepLimitado.length > 5) {
    const cepFormatado = cepLimitado.replace(/(\d{5})(\d{0,3})/, '$1-$2');

    return cepFormatado;
  }

  return cepLimitado;
}
