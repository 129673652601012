import PropTypes from "prop-types";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { useState } from "react";
// @mui
import {
  Box,
  List,
  ListItemText,
  Collapse,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { ExpandLess, ExpandMore, Summarize } from "@mui/icons-material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(false);

  const { pathname } = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {children ? (
        <List className="p-0">
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <Summarize />
            </ListItemIcon>
            <ListItemText disableTypography primary={title} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {children.map(({ title, path, icon }, index) => (
              <StyledNavItem
                component={RouterLink}
                to={path}
                key={index}
                sx={{
                  bgcolor:
                    pathname?.includes("dashboard/ticket") &&
                    title === "Vistorias"
                      ? "action.selected"
                      : "",
                  color:
                    pathname?.includes("dashboard/ticket") &&
                    title === "Vistorias"
                      ? "text.primary"
                      : "",
                  fontWeight:
                    pathname?.includes("dashboard/ticket") &&
                    title === "Vistorias"
                      ? "fontWeightBold"
                      : "",
                  "&.active": {
                    color: "text.primary",
                    bgcolor: "action.selected",
                    fontWeight: "fontWeightBold",
                  },
                }}
              >
                <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
                <ListItemText disableTypography primary={title} />
              </StyledNavItem>
            ))}
          </Collapse>
        </List>
      ) : (
        <StyledNavItem
          component={RouterLink}
          to={path}
          sx={{
            bgcolor:
              pathname?.includes("dashboard/ticket") && title === "Vistorias"
                ? "action.selected"
                : "",
            color:
              pathname?.includes("dashboard/ticket") && title === "Vistorias"
                ? "text.primary"
                : "",
            fontWeight:
              pathname?.includes("dashboard/ticket") && title === "Vistorias"
                ? "fontWeightBold"
                : "",
            "&.active": {
              color: "text.primary",
              bgcolor: "action.selected",
              fontWeight: "fontWeightBold",
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </StyledNavItem>
      )}
    </>
  );
}
