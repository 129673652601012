export const saveProfilePicInLocalStorage = async (url) => {
  const response = await fetch(url);

  const blob = await response.blob();
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      localStorage.setItem("Kinto_admin_profilePic", reader.result);
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(new Error("Error reading the image"));
    };

    reader.readAsDataURL(blob);
  });
};

export function saveProfilePicInLocalStorageFile(file, save) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = reader.result;
      resolve(base64String);
      if (save) {
        sessionStorage.setItem("Kinto_admin_profilePic", base64String);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}
