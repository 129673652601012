import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import AlertSnackbar from '../../../components/alert/AlertSnackbar';
// hooks
import { useLogin } from '../../../hooks/Services/useLogin';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { result, loginUser, isLoading, message, status } = useLogin();
  const [formData, setFormData] = useState({});
  const [showAlert, setShowAlert] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const handleAlert = () => {
    setShowAlert(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleLogin = () => {
    loginUser(formData);
  }

  useEffect(() => {
    if(message !== ''){
      setShowAlert(true)
    }
    if(result?.data !== undefined && result?.data.name){
      navigate('/dashboard');
    }
    
  }, [result, message])

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email" onChange={handleInputChange} />

        <TextField
          name="password"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel control={<Checkbox defaultChecked />} name="remember" label="Manter Conectado" />
        <Link component={RouterLink} variant="subtitle2" underline="hover" to={'/reset/password'}>
          Esqueceu a senha ?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin} loading={isLoading} >
        Entrar
      </LoadingButton>

      <AlertSnackbar openAlert={showAlert} duration={2500} message={message} status={status} onClose={handleAlert} />
    </>
  );
}
