import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// sections
import {
  AppTasks,
  AppCurrentVisits,
  AppWidgetSummary,
} from "../sections/@dashboard/app";

import useDashboard from "../hooks/Services/useDashboard";
import { AppContext } from "../hooks/AppContext";
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { userName } = useContext(AppContext);
  const theme = useTheme();
  const { result, fetchDashboard, isLoading } = useDashboard();

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Olá, {userName.split(" ")[0]}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Em elaboração"
              total={result.data.elaborate}
              color="primary"
              icon={"lucide:edit"}
              linkTo="/dashboard/ticket/0"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Pendente aceitar"
              total={result.data.pending}
              color="warning"
              icon={"fluent:vehicle-car-16-filled"}
              linkTo="/dashboard/ticket/1"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Respondidos"
              total={result.data.finish}
              color="success"
              icon={"ic:round-pending-actions"}
              linkTo="/dashboard/ticket/5"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Cancelados"
              total={result.data.canceled}
              color="error"
              icon={"ic:round-cancel"}
              linkTo="/dashboard/ticket/3"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Resumo"
              chartData={[
                { label: "Finalizado", value: Number(result.data.finish) },
                { label: "Pendente", value: Number(result.data.pending) },
                { label: "Cancelado", value: Number(result.data?.canceled) },
                {
                  label: "Aguardando Orçamento",
                  value: Number(result.data?.waitOrc),
                },
              ]}
              chartColors={[
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
                theme.palette.error.main,
              ]}
              loading={isLoading}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks title="Tarefas" list={[]} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
