import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";

export default function useSurvey() {
  const navigate = useNavigate();
  const [result, setResult] = useState({ data: [] });
  const [params, setParams] = useState({ page: 0, rowsPerPage: 20 });
  const [message, setMessage] = useState({
    message: "",
    status: 0,
    show: false,
  });
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchSurvey = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/admin/list/surveys/${params.page}/${params.rowsPerPage}`
      );
      setResult(data);
    } catch {
      setResult({ data: [] });
    }
  }, []);

  const searchSurvey = useCallback(
    async (dataSearch) => {
      setIsLoading(true);

      try {
        const queryParams = {
          params: {
            all: dataSearch[0].all,
          },
        };
        const { data } = await api.get(
          `/admin/list/surveys/${params.page}/${params.rowsPerPage}`,
          queryParams
        );
        setResult(data);
      } catch {
        setResult({ data: [] });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const viewSurvey = useCallback(
    async (idSurvey) => {
      try {
        const { data } = await api.get(`/admin/datail/survey/${idSurvey}`);
        setResult(data);
      } catch {
        setResult({ data: [] });
      }
    },
    [params]
  );

  const createSurvey = useCallback(async (dataForm) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("aboutVeh", JSON.stringify(dataForm.formValues));
      formData.append("mode", JSON.stringify(dataForm.mode));

      Object.keys(dataForm.fotos).forEach((groupKey) => {
        const group = dataForm.fotos[groupKey];

        if (groupKey === "fileDocs") {
          const getArray = group.docVehicle || [];
          getArray.forEach((file) => {
            formData.append("arquivo", file);
            formData.append("ref", "docVehicle");
          });
        }

        if (groupKey === "vehiclePics") {
          const getArray = group.vehiclePics || [];
          getArray.forEach((file) => {
            formData.append("arquivo", file);
            formData.append("ref", "vehiclePics");
          });
        }
      });

      const { data } = await api.data("/admin/create/survey", formData);

      if (data) {
        setMessage({ message: "Sucesso", status: 200, show: true });
        setResult(data);
        setTimeout(() => {
          navigate("/dashboard/ticket/999");
        }, 500);
      } else {
        setResult({ data: [] });
        setMessage({
          message: "Erro ao criar vistoria",
          status: 400,
          show: true,
        });
      }
    } catch (error) {
      setResult({ data: [] });
      setMessage({
        message: "Erro ao criar vistoria",
        status: 500,
        show: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const cancelSurvey = useCallback(async (surveyId) => {
    setIsLoading(true);

    let newMessage = { message: "", status: 0, show: false };

    try {
      const { data, status } = await api.put(
        "/admin/update/survey/status",
        surveyId
      );

      if (data) {
        newMessage = { message: data.message, status, show: true };
        setMessage(newMessage);
      }
    } catch {
      newMessage = { message: "Erro", status: 500, show: true };
      setMessage(newMessage);
    } finally {
      setIsLoading(false);
    }

    return newMessage;
  }, []);

  const toggleInativateDealer = useCallback(async (dealerId) => {
    setIsLoading(true);

    try {
      const { data } = await api.put("/admin/toggle-active/dealer", dealerId);

      if (data) {
        setMessage({ message: data.message, show: true });
      }
    } catch {
      console.log("error");
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    result,
    params,
    setParams,
    message,
    setMessage,
    status,
    createSurvey,
    fetchSurvey,
    searchSurvey,
    viewSurvey,
    cancelSurvey,
    toggleInativateDealer,
    isLoading,
  };
}
