import { useCallback, useState } from 'react';
import api from '../api';

export function useLogin() {
    const [result, setResult] = useState(null);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const loginUser = useCallback(async (userData) => {
        setIsLoading(true);

        try {
            const { data } = await api.post(`/login/user`, { ...userData, permission: 'ADMIN' });

            if (data) {
                setResult(data);
                setMessage(data.message);
                setStatus(data.status);
                localStorage.setItem('Token_Kinto_Admin', data.token)
            } else {
                setResult({});
                setMessage('Credenciais inválidas!');
                setStatus(400);
            }             
        } catch {
            setResult({});
            setStatus(500);
            setMessage('Sem conexão com o servidor!');
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { loginUser, result, isLoading, message, status };
}