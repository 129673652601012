import { useCallback, useState } from 'react';
import api from '../api';

export function useReset() {
    const [result, setResult] = useState(null);
    const [message, setMessage] = useState({ message: '', status: 0, show: false });
    const [isLoading, setIsLoading] = useState(false);

    const resetPassword = useCallback(async (userEmail) => {
        setIsLoading(true);

        const params = {
            email: userEmail
        }

        try {
            const { data } = await api.post(`/login/recovery`, params);

            if (data) {
                setMessage({ message: data.message, status: 200, show: true });
            } else {
                setMessage({ message: 'Erro ao solicitar nova senha', status: 500, show: true });
            }             
        } catch {
            setMessage({ message: 'Erro de conexão com o servidor', status: 500, show: true });
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { resetPassword, isLoading, message, setMessage };
}
