import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { getComparator, applySortFilter } from '../utils/sortFilterComparator';
import useStores from '../hooks/Services/useStores';
import SkeletonComponent from '../components/skeleton';
import DealerModal from '../components/modals/DealerModal';
import InativateDealer from '../components/modals/InativateDealer';
import AlertSnackbar from '../components/alert/AlertSnackbar';

import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { formatCNPJ, formatPhoneWithDDD } from '../utils/formatNumber';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'accountable', label: 'Responsável', alignRight: false },
  { id: 'active', label: 'Ativo', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function StoreList() {
  const navigate = useNavigate()
  const { result, searchDealer, params, setParams, isLoading, setMessage, message } = useStores()

  const [open, setOpen] = useState(null);

  const [openDealerForm, setOpenDealerForm] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const [openInativateDealer, setOpenInativateDealer] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterLojaValue, setFilterLojaValue] = useState('');

  const [filterResponsavelValue, setFilterResponsavelValue] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [infoDealer, setInfoDealer] = useState([]);

  const [mode, setMode] = useState('');

  const searchFields = [
    { title: 'Loja', apiField: 'name', type: 'text', onChange: (e) => setFilterLojaValue(e.target.value), inputValue: filterLojaValue },
    { title: 'Responsável', apiField: 'responsiblePerName', type: 'text', onChange: (e) => setFilterResponsavelValue(e.target.value), inputValue: filterResponsavelValue }
  ]

  const handleOpenMenu = (event, dealer) => {
    const dealerInformations = {
      ...dealer,
      cnpj: formatCNPJ(dealer.cnpj),
      additionalPhone: formatPhoneWithDDD(dealer.additionalPhone),
      responsiblePerPhone: formatPhoneWithDDD(dealer.responsiblePerPhone)
    }
    setInfoDealer(dealerInformations)
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';

    if (isDesc && orderBy === property) {
      setOrderBy('id');
      setOrder('desc');
    } else if (orderBy === property) {
      setOrder('desc')
    } else {
      setOrderBy(property);
      setOrder('asc')
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = result.data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleAlert = () => {
    setShowAlert(false);
    setMessage(prev => { 
      return {...prev, show: false};
    });
  }

  const resetFilters = () => {
    setPage(0);
    setFilterLojaValue('');
    setFilterResponsavelValue('');
    setParams({ ...params, page: 0 })
  }

  const handleCloseNewDealerModal = () => {
    setOpenDealerForm(false);
    setOpen(false);
  }

  const handleCloseInativateDealerModal = () => {
    setOpenInativateDealer(false);
    setOpen(false);
  }

  const filteredUsers = applySortFilter(result !== undefined ? result.data : [], getComparator(order, orderBy));

  const isNotFound = !filteredUsers.length && !isLoading;

  useEffect(() => {
    if (message.message !== '' && message.show) {
        setShowAlert(true);
    }
  }, [message, setMessage])

  return (
    <>
      <Helmet>
        <title>Lojas</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Dealer
          </Typography>

          <Button onClick={() => { setOpenDealerForm(true); setMode('create') }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Novo Dealer
          </Button>

        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            searchFunction={searchDealer}
            resetFilters={resetFilters}
            searchFields={searchFields}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={result !== undefined ? result.data.length : 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {result !== undefined && result.data.length !== 0 ? filteredUsers.map((row) => {
                    const { id, name, responsiblePerName, active, Users } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align='left'>
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{responsiblePerName}</TableCell>

                        <TableCell align="left">
                          <Label color={(active === 0 && 'error') || 'success'}>{active === 0 ? 'Não' : 'Sim'}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                    : isNotFound && (filterLojaValue !== '' || filterResponsavelValue !== '') ?
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <Paper sx={{ textAlign: 'center' }}>
                            <Typography variant="h6" paragraph>
                              Não encontrado
                            </Typography>
                            <Typography variant="body2">
                              Nenhum resultado para &nbsp;
                              <strong>&quot;{`Loja: ${filterLojaValue} Responsável: ${filterResponsavelValue}`}&quot;</strong>.
                              <br /> Tente verificar se há erros de digitação ou usar palavras completas.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    : isNotFound && filterLojaValue === '' && filterResponsavelValue === '' ?
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <Paper sx={{ textAlign: 'center' }}>
                            <Typography variant="h6" paragraph>
                              Não há registros
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    :
                      <>
                        <SkeletonComponent numberOfRows={4} />
                        <SkeletonComponent numberOfRows={4} />
                        <SkeletonComponent numberOfRows={4} />
                        <SkeletonComponent numberOfRows={4} />
                        <SkeletonComponent numberOfRows={4} />
                      </>
                  }
                </TableBody>

                <InativateDealer openModal={openInativateDealer} onClose={handleCloseInativateDealerModal} dealer={infoDealer} onSubmit={() => { resetFilters(); setMessage({ message: 'Sucesso', sstatus: 200, show: true }) }} /> 
                <DealerModal openModal={openDealerForm} onClose={handleCloseNewDealerModal} mode={mode} infoDealer={infoDealer} onSubmit={resetFilters} />
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            count={result && result.total !== undefined ? result.total : 0}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Resultado por página:"
            component="div"
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: '15px'
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: '15px'
              },
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
        </Card>
      </Container>

      <AlertSnackbar openAlert={showAlert} duration={2500} message={message.message} status={message.status} onClose={handleAlert} />

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenInativateDealer(true)}>
          {   
            infoDealer.active === 0 ?
              <>
                <Iconify icon={'material-symbols:done'} sx={{ mr: 2 }} />
                Ativar
              </>
            :
              <>
                <Iconify icon={'material-symbols:block'} sx={{ mr: 2 }} />
                Inativar
              </>
           }
        </MenuItem>

        <MenuItem onClick={
          () => {
            setOpenDealerForm(true)
            setMode('edit');
          }
        }>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Editar
        </MenuItem>
      </Popover>
    </>
  );
}
