import { useCallback, useState } from 'react';
import axios from 'axios';

export default function useUtils() {
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCep = useCallback(async (cep) => {
    setIsLoading(true);

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      if (response.status === 200) {
        setResult(response.data);
      }
    } catch {
      setResult({ status: 500, message: 'Sem conexão com o servidor!' });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { result, isLoading, fetchCep, setResult };
}