import React, { useEffect } from 'react';
import { Modal, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useSurveyHook from '../../hooks/Services/useSurvey';

const InativateDealer = ({ openModal, dealer, onClose, onSubmit }) => {
    const { toggleInativateDealer, message, isLoading } = useSurveyHook();

    // const handleInativateDealer = async () => {
    //     await toggleInativateDealer({ id: dealer.id });
    //     onSubmit();
    //     onClose(message);
    // }

    const handleInativateDealer = async () => {
        await toggleInativateDealer({ id: dealer.id});
    };

    useEffect(() => {
        if (!isLoading && message.show) {
            onSubmit();
            onClose(message);
        }
    }, [message, isLoading])
    return (
        <>
            <Modal open={openModal} onClose={onClose}>
                <Dialog open={openModal}>
                    <DialogTitle>
                        {dealer.active === 1 ? 'Inativar Dealer:' : 'Ativar Dealer:'} {dealer.name}?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {dealer.active === 1 ?   
                                'Ao inativar, os usuários da loja não serão mais capazes de acessar o sistema.'
                            :
                                'Ao ativar, os usuários da loja serão capazes de acessar o sistema.'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions> 
                        <LoadingButton variant="contained" loading={isLoading} color={dealer.active === 1 ? 'error' : 'success'} onClick={handleInativateDealer}>{dealer.active === 1 ? 'Inativar' : 'Ativar'}</LoadingButton>
                        <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

            </Modal>
        </>
    );
};

export default InativateDealer;
