import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
});

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('Token_Kinto_Admin');
    if (token) {
        config.headers.jwt = token;
    }
    return config;
});

const get = async (endpoint, data) => {
    try {
        const response = await instance.get(`${process.env.REACT_APP_API_ENDPOINT}${endpoint}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

const post = async (endpoint, data) => {
    try {
        const response = await instance.post(`${process.env.REACT_APP_API_ENDPOINT}${endpoint}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

const put = async (endpoint, data) => {
    try {
        const response = await instance.put(`${process.env.REACT_APP_API_ENDPOINT}${endpoint}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

const data = async (endpoint, data) => {
    try {
        const response = await instance.post(`${process.env.REACT_APP_API_ENDPOINT}${endpoint}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        return error;
    }
};

const api = {
    get,
    post,
    data,
    put,
};

export default api;
