import { Helmet } from "react-helmet-async";
import { useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import {
  getComparator,
  applySortFilter,
} from "../../utils/sortFilterComparator";
import { fDateBR } from "../../utils/formatTime";
import SkeletonComponent from "../../components/skeleton";
import useReportSurveys from "../../hooks/Services/useReportSurveys";

import Scrollbar from "../../components/scrollbar";
// sections
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/index";
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "plate", label: "Placa", alignRight: false },
  { id: "brand", label: "Loja", alignRight: false },
  { id: "model", label: "Modelo", alignRight: false },
  { id: "userName", label: "Usuário", alignRight: false },
  { id: "createdAt", label: "Criado em", alignRight: false },
];

// ----------------------------------------------------------------------

export default function ReportSurveys() {
  const { result, searchReportSurvey, params, setParams, isLoading } =
    useReportSurveys();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("createdAt");

  const [filterPlacaValue, setFilterPlacaValue] = useState("");

  const [filterUsuarioValue, setFilterUsuarioValue] = useState("");

  const [filterMesValue, setFilterMesValue] = useState({
    label: "",
    value: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const filterValues = [
    { field: "dealerName", value: "Dealer" },
    { field: "userName", value: "Usuário" },
    { field: "month", value: "Mes" },
  ];

  const searchFields = [
    {
      title: "Placa",
      apiField: "plate",
      type: "text",
      onChange: (e) => setFilterPlacaValue(e.target.value),
      inputValue: filterPlacaValue,
    },
    {
      title: "Usuario",
      apiField: "userName",
      type: "text",
      onChange: (e) => setFilterUsuarioValue(e.target.value),
      inputValue: filterUsuarioValue,
    },
    {
      title: "Mes",
      apiField: "createdAtMonth",
      type: "date",
      onChange: (value) => setFilterMesValue(value),
      inputValue: filterMesValue,
    },
  ];

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";

    if (isDesc && orderBy === property) {
      setOrderBy("createdAt");
      setOrder("desc");
    } else if (orderBy === property) {
      setOrder("desc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = result.data.map((n) => n.plate);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setParams({ ...params, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setParams({ ...params, rowsPerPage: parseInt(event.target.value, 10) });
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const resetFilters = () => {
    setPage(0);
    setFilterPlacaValue("");
    setFilterUsuarioValue("");
    setFilterMesValue({ label: "", value: "" });
    setParams({ ...params, page: 0 });
  };

  const filteredUsers = applySortFilter(
    result !== undefined ? result.data : [],
    getComparator(order, orderBy)
  );

  const isNotFound = !filteredUsers.length && !isLoading;

  return (
    <>
      <Helmet>
        <title>Relatório - Vistoria</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Relatório - Vistoria
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            searchFunction={searchReportSurvey}
            resetFilters={resetFilters}
            searchFields={searchFields}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={result !== undefined ? result.data.length : 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {result !== undefined && result.data.length !== 0 ? (
                    filteredUsers.map((row) => {
                      const {
                        id,
                        brand,
                        model,
                        plate,
                        User,
                        Store,
                        createdAt,
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          {/* selected={selectedSurvey} <TableCell padding="checkbox">
                          <Checkbox checked={selectedSurvery} onChange={(event) => handleClick(event, plate)} />
                        </TableCell> */}

                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              {plate}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            {Store?.name || ""}
                          </TableCell>

                          <TableCell align="left">
                            {brand} {model}
                          </TableCell>

                          <TableCell align="left">
                            {User ? User.name : "Nenhum vinculado"}
                          </TableCell>

                          <TableCell align="left">
                            {fDateBR(createdAt)}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : isNotFound &&
                    (filterPlacaValue !== "" ||
                      filterUsuarioValue !== "" ||
                      filterMesValue.value !== "") ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: "center" }}>
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>
                          <Typography variant="body2">
                            Nenhum resultado para &nbsp;
                            <strong>
                              &quot;
                              {`Dealer: ${filterPlacaValue} Usuario: ${filterUsuarioValue} Mes: ${filterMesValue.label}`}
                              &quot;
                            </strong>
                            .
                            <br /> Tente verificar se há erros de digitação ou
                            usar palavras completas.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : isNotFound &&
                    filterPlacaValue === "" &&
                    filterUsuarioValue === "" &&
                    filterMesValue.value === "" ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: "center" }}>
                          <Typography variant="h6" paragraph>
                            Não há registros
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      <SkeletonComponent numberOfRows={5} />
                      <SkeletonComponent numberOfRows={5} />
                      <SkeletonComponent numberOfRows={5} />
                      <SkeletonComponent numberOfRows={5} />
                      <SkeletonComponent numberOfRows={5} />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            count={result && result.total !== undefined ? result.total : 0}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Resultado por página:"
            component="div"
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: "15px",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "15px",
              },
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
        </Card>
      </Container>
    </>
  );
}
