import { useEffect, useState } from 'react';
import { Modal, Box, Grid, Card, Button, TextField } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { object, string } from 'yup';
import AlertSnackbar from '../alert/AlertSnackbar';
import useUsers from '../../hooks/Services/useUsers';

const ListUserModal = ({ openModal, onClose, mode, infoUser, onSubmit }) => {
    const { createUser, message, setMessage, isLoading, updateUser } = useUsers();
    const [formData, setFormData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const schema = object().shape({
        email: string().email('Email inválido').required('O email é necessário'),
        name: string().required('O nome é necessário'),
        password: mode === 'create' && (string().required('A senha é necessária')),
    });

      const handleAlert = () => {
        setShowAlert(false);
        setMessage(prev => { 
            return {...prev, show: false};
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

    const handleSubmit = (e) => {
        schema.validate(formData, { abortEarly: false })
            .then( async () => {
                if (mode === 'create') {
                    await createUser(formData);
                    setFormData({})
                } else {
                    await updateUser(formData);
                }
                onSubmit();
            })
            .catch(error => { 
                setMessage({ message: error.errors[0], show: true, status: 0 })
            }
                )
    }

    useEffect(() => {
        if (message.message !== '' && message.show) {
            setShowAlert(true);
        }
    }, [message])

    useEffect(() => {
        if (mode === 'edit') {
            setFormData({ 
                ...infoUser,
            });
        }
    }, [mode, openModal])

    return (
        <Modal open={openModal} onClose={onClose} >
            <Box className='d-flex justify-content-center align-items-center h-100'>
            <Card className='w-75 align-self-center p-3'>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <TextField id="outlined-basic" label="Nome" name='name' variant="outlined" className='w-100' value={formData.name || ''} onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField id="outlined-basic" label="Email" name='email' variant="outlined" className='w-100' value={formData.email || ''} onChange={handleInputChange} />
                </Grid>
                {mode === 'create' && <Grid item xs={12} md={12}>
                    <TextField id="outlined-basic" label="Senha" name='password' variant="outlined" className='w-100' value={formData.password || ''} onChange={handleInputChange} />
                </Grid>}
                <Grid item xs={6} md={6} mt={2}>
                    <LoadingButton className='w-100' loading={isLoading} variant='outlined' onClick={handleSubmit}>{mode === 'edit' ? 'Atualizar' : 'Criar'}</LoadingButton>
                </Grid>
                <Grid item xs={6} md={6} mt={2}>
                    <Button className='w-100' variant='outlined' color='error' onClick={onClose}>Cancelar</Button>
                </Grid>
                </Grid>
                <AlertSnackbar openAlert={showAlert} duration={2500} status={message.status} message={message.message} onClose={handleAlert} />
            </Card>
            </Box>
        </Modal>
    )
}

export default ListUserModal;