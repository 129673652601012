import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import { getComparator, applySortFilter } from '../utils/sortFilterComparator';
import useUsers from '../hooks/Services/useUsers';
import SkeletonComponent from '../components/skeleton';
import ListUsersModal from '../components/modals/ListUsersModal';
import InativateUserModal from '../components/modals/InativateUserModal';

import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import AlertSnackbar from '../components/alert/AlertSnackbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'role', label: 'Acesso', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'active', label: 'Ativo', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function ListUser() {
  const { result, searchUsers, params, setParams, isLoading, setMessage, message } = useUsers()
  
  const [open, setOpen] = useState(null);

  const [openUserModal, setOpenUserModal] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const [openInativateUserModal, setOpenInativateUserModal] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterNomeValue, setFilterNomeValue] = useState('');

  const [filterEmailValue, setFilterEmailValue] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [infoUser, setInfoUser] = useState([]);

  const [mode, setMode] = useState('')

  const filteredUsers = applySortFilter(result !== undefined ? result.data : [], getComparator(order, orderBy));

  const isNotFound = !filteredUsers.length && !isLoading;

  const searchFields = [
    { title: 'Nome', apiField: 'name', type: 'text', onChange: (e) => setFilterNomeValue(e.target.value), inputValue: filterNomeValue},
    { title: 'Email', apiField: 'email', type: 'text', onChange: (e) => setFilterEmailValue(e.target.value), inputValue: filterEmailValue}
  ];

  const handleOpenMenu = (event, user) => {
    setInfoUser(user);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null); 
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';

    if (isDesc && orderBy === property) {
      setOrderBy('id');
      setOrder('desc');
    } else if (orderBy === property) {
      setOrder('desc')
    } else {
      setOrderBy(property);
      setOrder('asc')
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = result.data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const resetFilters = () => {
    setPage(0);
    setFilterEmailValue('');
    setFilterNomeValue('');
    setParams({ ...params, page: 0 })
  }

  const handleCloseNewUserModal = () => {
    setOpenUserModal(false);
    setOpen(false);
  }

  const handleCloseInativateUserModal = () => {
    setOpenInativateUserModal(false);
    setOpen(false);
  }

  const handleAlert = () => {
    setShowAlert(false);
    setMessage(prev => { 
      return {...prev, show: false};
    });
  }

  useEffect(() => {
    if (message.message !== '' && message.show) {
        setShowAlert(true);
    }
  }, [message, setMessage])

  return (
    <>
      <Helmet>
        <title>Usuários</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuários
          </Typography>

          <Button onClick={() => { setOpenUserModal(true); setMode('create') }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Novo Usuário
          </Button>

        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            searchFunction={searchUsers}
            resetFilters={resetFilters}
            searchFields={searchFields}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={result !== undefined ? result.data.length : 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {result !== undefined && result.data.length !== 0 ? filteredUsers.map((row) => {
                    const { id, name, role, email, active } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align='left'>
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{role}</TableCell>

                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">
                          <Label color={(active === 0 && 'error') || 'success'}>{active === 0 ? 'Não' : 'Sim'}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      );
                    })
                    : isNotFound && (filterNomeValue !== '' || filterEmailValue !== '') ?
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <Paper sx={{ textAlign: 'center' }}>
                            <Typography variant="h6" paragraph>
                              Não encontrado
                            </Typography>
                            <Typography variant="body2">
                              Nenhum resultado para &nbsp;
                              <strong>&quot;{`Nome: ${filterNomeValue} Email: ${filterEmailValue}`}&quot;</strong>.
                              <br /> Tente verificar se há erros de digitação ou usar palavras completas.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                      : isNotFound && filterNomeValue === '' && filterEmailValue === '' ?
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <Paper sx={{ textAlign: 'center' }}>
                              <Typography variant="h6" paragraph>
                                Não há registros
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      :
                        <>
                          <SkeletonComponent numberOfRows={6} />
                          <SkeletonComponent numberOfRows={6} />
                          <SkeletonComponent numberOfRows={6} />
                          <SkeletonComponent numberOfRows={6} />
                          <SkeletonComponent numberOfRows={6} />
                        </>
                  }
                </TableBody>

                <InativateUserModal openModal={openInativateUserModal} user={infoUser} onClose={handleCloseInativateUserModal} onSubmit={() => { resetFilters(); setMessage({ message: 'Sucesso', status: 200, show: true }) }} />
                <ListUsersModal openModal={openUserModal} onClose={handleCloseNewUserModal} mode={mode} infoUser={infoUser} onSubmit={resetFilters} />
                
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            count={result && result.total !== undefined ? result.total : 0}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Resultado por página:"
            component="div"
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: '15px'
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: '15px'
              },
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
        </Card>
        <AlertSnackbar openAlert={showAlert} duration={2500} status={message.status} message={message.message} onClose={handleAlert} />
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={(e) => setOpenInativateUserModal(true) }>
          {
            infoUser.active === 0 ?
              <>
                <Iconify icon={'material-symbols:done'} sx={{ mr: 2 }} />
                Ativar
              </>
            :
              <>
                <Iconify icon={'material-symbols:block'} sx={{ mr: 2 }} />
                Inativar
              </>
          }
        </MenuItem>

        <MenuItem onClick={
          () => {
            setOpenUserModal(true);
            setMode('edit');
          }
        }>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Editar
        </MenuItem>
      </Popover>
    </>
  );
}
