import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  LinearProgress,
} from "@mui/material";

const steps = [
  "Pendente",
  "Aceito",
  "Em auditoria",
  "Etapa final",
  "Finalizado",
];

export default function TimeLine(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  return Number.isNaN(Number(props.status)) ? (
    <Box className="w-100">
      <LinearProgress />
    </Box>
  ) : (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={
          props.status === 0
            ? 0
            : props.status === 1
            ? 1
            : props.status === 4
            ? 2
            : props.status === 5
            ? 5
            : props.status === 6
            ? 5
            : 6
        }
      >
        {steps.map((label, index) => {
          const stepProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography className="w-100 text-center fs-3" sx={{ mt: 2, mb: 1 }}>Auditoria finalizada!</Typography>
        </>
      ) : (
        <>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
        </>
      )}
      {props?.status === 3 && (
        <>
          <Typography className="w-100 text-center fs-3 text-danger" sx={{ mt: 2, mb: 1 }}>Auditoria cancelada!</Typography>
        </>
      )}
      {props?.status === 2 && (
        <>
          <Typography className="w-100 text-center fs-3 text-danger" sx={{ mt: 2, mb: 1 }}>Auditoria rejeitada!</Typography>
        </>
      )}
      {props?.status === 6 && (
        <>
          <Typography className="w-100 text-center fs-3 text-danger" sx={{ mt: 2, mb: 1 }}>Auditoria em disputa!</Typography>
        </>
      )}
    </Box>
  );
}
