import React from 'react';
import { Modal, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useSurvey from '../../hooks/Services/useSurvey';

const CancelSurveyModal = ({ openModal, survey, onClose, onSubmit }) => {
    const { cancelSurvey, isLoading } = useSurvey();

    const handleCancelSurvey = async () => {
        const apiMessage = await cancelSurvey({ id: survey.id });
        onSubmit(apiMessage);
        onClose();
    }

    return (
        <>
            <Modal open={openModal} onClose={onClose}>
                <Dialog open={openModal}>
                    <DialogTitle>
                        {survey.status === 3 ? `Ativar Vistoria: ${survey.plate}` : `Rejeitar Vistoria: ${survey.plate}`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {
                                survey.status === 3 ?
                                    'Ao ativar, será possível responder a vistoria'
                                :
                                    'Ao rejeitar, não será possível responder a vistoria.'
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions> 
                        <LoadingButton variant="contained" loading={isLoading} color='error' onClick={handleCancelSurvey}>{survey.status === 3 ? 'Ativar' : 'Rejeitar'}</LoadingButton>
                        <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

            </Modal>
        </>
    );
};

export default CancelSurveyModal;
