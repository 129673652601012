import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography, Box } from "@mui/material";

import useDashboard from "../hooks/Services/useDashboard";
import { AppContext } from "../hooks/AppContext";
import HomeWidgetSummary from "../components/Dashboard/Home/HomeWidgetSummary";
// ----------------------------------------------------------------------

export default function DashboardHomePage() {
  const { userName } = useContext(AppContext);
  const theme = useTheme();
  const { result, fetchDashboard, isLoading } = useDashboard();

  return (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Container
        maxWidth="xl"
        sx={{
          width: "90%",
          padding: "0",
        }}
      >
        <Typography variant="h4" sx={{ mb: 5 }}>
          Olá, {userName.split(" ")[0]}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <HomeWidgetSummary
              to="/dashboard/new-ticket"
              title="+ NOVA VISTORIA"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <HomeWidgetSummary
              to="/dashboard/app"
              title="DASHBOARD"
              color="info"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <HomeWidgetSummary
              to="/dashboard/ticket/999"
              title="VISTORIAS"
              color="warning"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <HomeWidgetSummary
              to="/dashboard/reports/financial"
              title="RELATÓRIOS"
              color="error"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
