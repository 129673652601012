import React from 'react';
import { Modal, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useUsers from '../../hooks/Services/useUsers';

const InativateUserModal = ({ openModal, user, onClose, onSubmit }) => {
    const { toggleActiveUser, isLoading } = useUsers();

    const handleInativateUser = async () => {
        await toggleActiveUser({ id: user.id });
        onSubmit();
        onClose();
    }

    return (
        <>
            <Modal open={openModal} onClose={onClose}>
                <Dialog open={openModal}>
                    <DialogTitle>
                        {user.active === 1 ? 'Inativar Usuário:' : 'Ativar usuário'} {user.name}?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {user.active === 1 ?   
                                'Ao inativar, o usuário não será mais capaz de acessar o sistema.'
                            :
                                'Ao ativar, o usuário será capaz de acessar o sistema.'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions> 
                        <LoadingButton variant="contained" loading={isLoading} color={user.active === 1 ? 'error' : 'success'} onClick={handleInativateUser}>{user.active === 1 ? 'Inativar' : 'Ativar'}</LoadingButton>
                        <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

            </Modal>
        </>
    );
};

export default InativateUserModal;
