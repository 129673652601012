import { Box, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const RenderPdfs = ({ pdfArray }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        width: "100%",
        alignItems: "center",
        marginTop: "1rem",
        padding: "1rem",
        flexWrap: "wrap",
      }}
    >
      {pdfArray && pdfArray?.length !== 0 ? (
        pdfArray?.map((pdf) => {
          const pdfName = pdf.pdf.split("_")[1] || pdf.pdf || "";
          const pdfUrl = pdf.pdf || "";

          return (
            <Link
              to={`${process.env.REACT_APP_API_ENDPOINT}/replyes/${pdfUrl}`}
              style={{
                textDecoration: "none",
                display: "contents",
                color: "#1e1e1e",
              }}
              target="_blank"
            >
              <Box
                sx={{
                  width: "15%",
                  textAlign: "center",
                }}
              >
                <Icon
                  icon="bx:file"
                  width={100}
                  style={{
                    marginRight: "12px",
                  }}
                />

                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {pdfName}
                </Typography>
              </Box>
            </Link>
          );
        })
      ) : (
        <Box>
          <Typography
            sx={{
              padding: "1rem",
            }}
          >
            Nenhum PDF foi anexado.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default RenderPdfs;
