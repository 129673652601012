const { TableRow, TableCell, Skeleton } = require("@mui/material")


const SkeletonComponent = ({ numberOfRows }) => (
    <TableRow>
        {
            [...Array(numberOfRows)].map((el, index) => {
                return (
                    <TableCell key={index}>
                        <Skeleton variant='rectangular' />
                    </TableCell>
                )
            })
        }
    </TableRow>
)

export default SkeletonComponent;