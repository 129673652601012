import { useEffect, useState, useRef } from 'react';
import { Modal, Box, Grid, Card, Button, TextField, Typography, Checkbox } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { object, string } from 'yup';
import AlertSnackbar from '../alert/AlertSnackbar';
import useStores from '../../hooks/Services/useStores';
import useUsers from '../../hooks/Services/useUsers';
import Scrollbar from '../scrollbar/Scrollbar';
import { formatCNPJ, formatPhoneWithDDD } from '../../utils/formatNumber';

const DealerModal = ({ openModal, onClose, mode, infoDealer, onSubmit }) => {
    const { createDealer, updateDealer, message, setMessage, isLoading, newStore } = useStores();
    const { createUser } = useUsers();
    const [formData, setFormData] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [newUserCheckbox, setNewUserCheckbox] = useState(false);
    const ref = useRef(null);

    const schema = object().shape({
        businessName:
            string()
                .required('A razão social é necessária.'),
        name:
            string()
                .required('O nome da loja é necessário.'),
        cnpj:
            string()
                .test('isCorrect', 'CNPJ inválido', (cnpj) => {
                    return /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(cnpj);
                })
                .required('O CNPJ da loja é necessário.'),
        completeAddress:
            string()
                .required('O endereço completo da loja é necessário.'),
        responsiblePerMail:
            string()
                .email('Digite um email válido para o responsável.')
                .required('O email do responsável é necessário.'),
        responsiblePerPassword: 
            newUserCheckbox && string()
                .required('A senha do resposável é necessária.'),
        responsiblePerName:
            string()
                .required('O nome do responsável é necessário.'),
        responsiblePerPhone:
            string()
                .test('isCorrect', 'O telefone do responsável deve ter exatamente 11 números', (cnpj) => {
                    return /^\(\d{2}\)\s\d{5}-\d{4}$/.test(cnpj);
                })
                .required('O telefone do responsável é necessário.'),
        consultantPerMail:
            string()
                .email('Digite um email válido para o consultor.')
                .required('O email do consultor é necessário.'),
        consultantPerName:
            string()
                .required('O nome do consultor é necessário.'),
        additionalPhone:
            string()
                .test('isCorrect', 'O telefone adicional deve ter exatamente 11 números', (cnpj) => {
                    return /^\(\d{2}\)\s\d{5}-\d{4}$/.test(cnpj);
                })
                .required('O telefone adicional é necessário.'),
        responsibleNameInspection:
            string()
                .required('O nome do responsável pelas vistorias é necessário.'),
    });

    const handleAlert = () => {
        setShowAlert(false);
        setMessage(prev => {
            return { ...prev, show: false };
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'cnpj') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: formatCNPJ(value),
            }));
        } else if (name === 'responsiblePerPhone' || name === 'additionalPhone') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: formatPhoneWithDDD(value),
            }))
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = () => {
        schema.validate(formData, { abortEarly: false })
            .then(async () => {
                if (mode === 'create') {
                    await createDealer(formData);
                    
                    if (newUserCheckbox) {
                        const { id } = newStore.data;

                        const newUser = {
                            name: formData.responsiblePerName,
                            store: { id },
                            password: formData.responsiblePerPassword,
                            role: { label: 'USER' },
                            email: formData.responsiblePerMail
                        }

                        await createUser(newUser);
                    }

                } else {
                    await updateDealer(formData);
                }

                onSubmit();
            })
            .catch(error => setMessage({ message: error.errors[0], show: true, status: 500 }))
    }

    useEffect(() => {
        if (message.message !== '' && message.show) {
            setShowAlert(true);
        }
    }, [message])

    useEffect(() => {
        if (mode === 'edit') {
            setFormData({ name: infoDealer.name, ...infoDealer });
        } else if (mode === 'create') {
            setFormData({})
        }
    }, [mode, openModal])


    return (   
        <Modal open={openModal} onClose={onClose} className='d-grid'>
            <Scrollbar ref={ref}>
                <Box>
                    <Card className='w-75 align-self-center p-3 m-auto'>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                            <Grid item xs={12}>
                                <Typography variant='h5'>Dados da loja</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="Nome da loja" name='name' variant="outlined" className='w-100' value={formData.name || ''} onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="CNPJ" name='cnpj' variant="outlined" className='w-100' value={formData.cnpj || ''} onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="Razão social" name='businessName' variant="outlined" className='w-100' value={formData.businessName || ''} onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="Endereço completo" name='completeAddress' variant="outlined" className='w-100' value={formData.completeAddress || ''} onChange={handleInputChange} />
                            </Grid>

                            <Grid item xs={mode === 'edit' ? 12 : 6}>
                                <Typography variant='h5'>Dados do responsável</Typography>
                            </Grid>
                            {mode === 'create' && 
                                <Grid item xs={6} md={6} className='d-flex align-items-center'>
                                    <Checkbox onChange={() => setNewUserCheckbox(!newUserCheckbox)}/>
                                    <Typography variant='h6'>Cadastrar novo usuário?</Typography>
                                </Grid>
                            }
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="Nome" name='responsiblePerName' variant="outlined" className='w-100' value={formData.responsiblePerName || ''} onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="Telefone" name='responsiblePerPhone' variant="outlined" className='w-100' value={formData.responsiblePerPhone || ''} onChange={handleInputChange} />
                            </Grid>
                            {mode === 'create' && newUserCheckbox &&
                                <Grid item xs={12} md={6}>
                                    <TextField id="outlined-basic" label="Senha" name='responsiblePerPassword' variant="outlined" className='w-100' value={formData.responsiblePerPassword || ''} onChange={handleInputChange} />
                                </Grid>
                            }
                            <Grid item xs={12} md={newUserCheckbox ? 6 : 12}>
                                <TextField id="outlined-basic" label="Email" name='responsiblePerMail' variant="outlined" className='w-100' value={formData.responsiblePerMail || ''} onChange={handleInputChange} />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h5'>Dados do consultor</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="Email" name='consultantPerMail' variant="outlined" className='w-100' value={formData.consultantPerMail || ''} onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="Nome" name='consultantPerName' variant="outlined" className='w-100' value={formData.consultantPerName || ''} onChange={handleInputChange} />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h5'>Dados adicionais</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="telefone adicional" name='additionalPhone' variant="outlined" className='w-100' value={formData.additionalPhone || ''} onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id="outlined-basic" label="Responsável pelas vistorias" name='responsibleNameInspection' variant="outlined" className='w-100' value={formData.responsibleNameInspection || ''} onChange={handleInputChange} />
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <LoadingButton className='w-100' loading={isLoading} variant='outlined' onClick={handleSubmit}>{mode === 'create' ? 'Criar' : 'Editar' } </LoadingButton>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Button className='w-100' variant='outlined' color='error' onClick={onClose}>Cancelar</Button>
                            </Grid>
                        </Grid>
                        <AlertSnackbar openAlert={showAlert} duration={2500} status={message.status} message={message.message} onClose={handleAlert} />
                    </Card>
                </Box>
            </Scrollbar>
        </Modal>
    )
}

export default DealerModal;