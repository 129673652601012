import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";

// components
import { useNavigate } from "react-router-dom";
import { getComparator, applySortFilter } from "../utils/sortFilterComparator";
import SkeletonComponent from "../components/skeleton";
import useSurvey from "../hooks/Services/useSurvey";
import CancelSurveyModal from "../components/modals/CancelSurveyModal";
import AlertSnackbar from "../components/alert/AlertSnackbar";

import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { fDateBR } from "../utils/formatTime";
import Kanban from "../components/Kanban";
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "brand", label: "Marca", alignRight: false },
  { id: "model", label: "Modelo", alignRight: false },
  { id: "year", label: "Ano", alignRight: false },
  { id: "plate", label: "Placa", alignRight: false },
  { id: "cod", label: "Cod. Interno", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "createdAt", label: "Criado em", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function UserPage() {
  const navigate = useNavigate();
  const {
    result,
    searchSurvey,
    params,
    setParams,
    isLoading,
    setMessage,
    message,
  } = useSurvey();

  const [open, setOpen] = useState(null);

  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("id");

  const [filterAllValue, setFilterAllValue] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [rselected, C] = useState("");

  const searchFields = [
    {
      title: "Pesquisar",
      apiField: "all",
      type: "text",
      onChange: (e) => setFilterAllValue(e.target.value),
      inputValue: filterAllValue,
    },
  ];

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";

    if (isDesc && orderBy === property) {
      setOrderBy("id");
      setOrder("desc");
    } else if (orderBy === property) {
      setOrder("desc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = result.data.map((n) => n.plate);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setParams({ ...params, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setParams({ ...params, rowsPerPage: parseInt(event.target.value, 10) });
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const resetFilters = () => {
    setPage(0);
    setFilterAllValue("");
    setParams({ ...params, page: 0 });
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  const handleAlert = () => {
    setShowAlert(false);
    setMessage((prev) => {
      return { ...prev, show: false };
    });
  };

  useEffect(() => {
    if (message.message !== "" && message.show) {
      setShowAlert(true);
    }
  }, [message, setMessage]);

  const filteredUsers = applySortFilter(
    result !== undefined ? result.data : [],
    getComparator(order, orderBy)
  );

  const isNotFound = !filteredUsers.length && !isLoading;

  return (
    <>
      <Helmet>
        <title>Vistorias</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Vistorias
          </Typography>
          <Button
            onClick={() => {
              navigate("/dashboard/new-ticket");
            }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Nova vistoria
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            searchFunction={searchSurvey}
            resetFilters={resetFilters}
            searchFields={searchFields}
          />

          <Kanban datas={filteredUsers} resetFilters={resetFilters} />

          {/* <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={result !== undefined ? result.data.length : 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {result !== undefined && result.data.length !== 0 ? (
                    filteredUsers.map((row) => {
                      const {
                        id,
                        brand,
                        model,
                        year,
                        plate,
                        cod,
                        status,
                        createdAt,
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">{brand}</TableCell>

                          <TableCell align="left">{model}</TableCell>

                          <TableCell align="left">{year}</TableCell>

                          <TableCell align="left">{plate}</TableCell>

                          <TableCell align="left">{cod}</TableCell>

                          <TableCell align="left">
                            <Label
                              color={
                                status === 0
                                  ? "default"
                                  : status === 1
                                  ? "primary"
                                  : status === 2
                                  ? "error"
                                  : status === 3
                                  ? "error"
                                  : status === 6
                                  ? "error"
                                  : "success"
                              }
                            >
                              {status === 0
                                ? "Pendente"
                                : status === 1
                                ? "Aceito"
                                : status === 2
                                ? "Rejeitado"
                                : status === 3
                                ? "Cancelado"
                                : status === 4
                                ? "Respondido"
                                : status === 5
                                ? "Finalizado"
                                : status === 6
                                ? "Em disputa"
                                : "Erro"}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            {fDateBR(createdAt)}
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => {
                                handleOpenMenu(e);
                                setRSelected(row);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : isNotFound &&
                    (filterDealerValue !== "" ||
                      filterPlacaValue !== "" ||
                      filterCodigoValue !== "") ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: "center" }}>
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>
                          <Typography variant="body2">
                            Nenhum resultado para &nbsp;
                            <strong>
                              &quot;
                              {`Dealer: ${filterDealerValue} Placa: ${filterPlacaValue} Codigo: ${filterCodigoValue}`}
                              &quot;
                            </strong>
                            .
                            <br /> Tente verificar se há erros de digitação ou
                            usar palavras completas.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : isNotFound &&
                    filterDealerValue === "" &&
                    filterPlacaValue === "" &&
                    filterCodigoValue === "" ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: "center" }}>
                          <Typography variant="h6" paragraph>
                            Não há registros
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      <SkeletonComponent numberOfRows={8} />
                      <SkeletonComponent numberOfRows={8} />
                      <SkeletonComponent numberOfRows={8} />
                      <SkeletonComponent numberOfRows={8} />
                      <SkeletonComponent numberOfRows={8} />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar> */}

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={result.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}

          <TablePagination
            count={result && result.total !== undefined ? result.total : 0}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Resultado por página:"
            component="div"
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: "15px",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "15px",
              },
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
        </Card>
        <AlertSnackbar
          openAlert={showAlert}
          duration={2500}
          status={message.status}
          message={message.message}
          onClose={handleAlert}
        />
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/dashboard/view-ticket", { state: rselected });
          }}
        >
          <Iconify icon={"majesticons:open"} sx={{ mr: 2 }} />
          Abrir
        </MenuItem>

        {rselected.status <= 3 && (
          <MenuItem
            sx={{ color: "error.main" }}
            onClick={() => setOpenCancelModal(true)}
          >
            {rselected.status === 3 ? (
              <>
                <Iconify icon={"fluent-mdl2:accept-medium"} sx={{ mr: 2 }} />
                Ativar
              </>
            ) : (
              <>
                <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                Rejeitar
              </>
            )}
          </MenuItem>
        )}
      </Popover>
    </>
  );
}
