// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: "Início",
    path: "/dashboard/home",
    icon: icon("ic_home"),
  },
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: icon("ic_dashboard"),
  },
  {
    title: "Vistorias",
    path: "/dashboard/ticket/999",
    icon: icon("ic_ticket"),
  },
  // {
  //   title: 'Dealer',
  //   path: '/dashboard/dealer',
  //   icon: icon('ic_shop'),
  // },
  // {
  //   title: 'Usuarios',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  {
    title: "Relatórios",
    icon: icon("ic_analytics"),
    children: [
      {
        title: "Financeiro",
        path: "/dashboard/reports/financial",
        icon: icon("ic_analytics"),
      },
      {
        title: "Vistorias",
        path: "/dashboard/reports/surveys",
        icon: icon("ic_ticket"),
      },
    ],
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
