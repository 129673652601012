import { useCallback, useState } from 'react';
import api from '../api';

export default function useReportSurveys() {
    const [result, setResult] = useState({ data: [] });
    const [params, setParams] = useState({ page: 0, rowsPerPage: 20 });
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const searchReportSurvey = useCallback(async (dataSearch) => {
        setIsLoading(true);

        try {
            const queryParams = {
                params: {
                    plate: dataSearch[0].plate,
                    userName: dataSearch[1].userName,
                    createdAtMonth: dataSearch[2].createdAtMonth.value
                },
            };
            const { data } = await api.get(`/admin/list/report/surveys/${params.page}/${params.rowsPerPage}`, queryParams);
            setResult(data);
        } catch {
            setResult({ data: [] });
        } finally {
            setIsLoading(false);
        }
    }, [params])

    return { result, params, setParams, message, status, searchReportSurvey, isLoading };
}