import { Helmet } from "react-helmet-async";
import { useContext, useEffect, useRef, useState } from "react";
import { object, string, mixed, number } from "yup";
import InputMask from "react-input-mask";
// @mui
import {
  Card,
  Stack,
  Checkbox,
  Container,
  Typography,
  Box,
  TextField,
  Grid,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
// components
import { MuiFileInput } from "mui-file-input";
import { LoadingButton } from "@mui/lab";
import croqui from "../img/croqui.png";
import point from "../img/point.png";

import useSurvey from "../hooks/Services/useSurvey";
import useUtils from "../hooks/Services/useUtils";

import Scrollbar from "../components/scrollbar";
import AlertSnackbar from "../components/alert/AlertSnackbar";
import { formatCEP } from "../utils/formatNumber";
import { SnackBarContext } from "../context/SnackBarContext";

export default function NewTicket() {
  const { createSurvey, isLoading, message, setMessage } = useSurvey();
  const { fetchCep, result: resultCep, isLoading: loadingCep } = useUtils();
  const [showAlert, setShowAlert] = useState(false);
  const [formValues, setFormValues] = useState({});

  const { setSnackBarMessage } = useContext(SnackBarContext);

  const placaRegExp = /^[A-Za-z]{3}-?\d{4}$|^[A-Za-z]{3}\d[A-Za-z]\d{2}$/i;
  const chassiRegExp =
    /^[A-Za-z0-9]{3,3}[A-Za-z0-9]{6,6}[A-Za-z0-9]{2,2}[A-Za-z0-9]{6,6}$/i;

  const croquicar = useRef(null);
  const partSelectionLayerRef = useRef(null);
  const [divCroqui, setDivCroqui] = useState();
  const [parteSelecionada, setParteSelecionada] = useState("");

  useEffect(() => {
    if (croquicar.current) {
      const divElement = croquicar.current;
      setDivCroqui(croquicar.current);
    }
  }, []);

  const partesDoCarro = [
    "Superior Esquerda",
    "Superior Central",
    "Superior Direita",
    "Lateral Esquerda",
    "Lateral Direita",
    "Inferior Esquerda",
    "Inferior Central",
    "Inferior Direita",
    "Teto",
  ];

  const handlePartSelection = (event) => {
    const { clientX, clientY, target } = event;
    const { left, top, width, height } = target.getBoundingClientRect();

    const xRelativeToElement = clientX - left;
    const yRelativeToElement = clientY - top;

    const relativeXPosition = xRelativeToElement / width;
    const relativeYPosition = yRelativeToElement / height;

    let parteSelecionada = "";

    if (relativeYPosition <= 0.3) {
      parteSelecionada =
        relativeXPosition <= 0.33
          ? "Superior Esquerda"
          : relativeXPosition <= 0.66
          ? "Superior Central"
          : "Superior Direita";
    } else if (relativeYPosition <= 0.7) {
      parteSelecionada =
        relativeXPosition <= 0.33
          ? "Lateral Esquerda"
          : relativeXPosition <= 0.66
          ? relativeYPosition <= 0.5
            ? "Teto"
            : "Inferior Central"
          : "Lateral Direita";
    } else {
      parteSelecionada =
        relativeXPosition <= 0.33
          ? "Inferior Esquerda"
          : relativeXPosition <= 0.66
          ? "Inferior Central"
          : "Inferior Direita";
    }

    setParteSelecionada(parteSelecionada);
  };

  const calcularCoordenadasParte = (parte) => {
    if (croquicar.current === null) {
      return 0;
    }
    switch (parte) {
      case "0":
        return { left: divCroqui.offsetWidth / 2 - 110, top: 100 };
      case "1":
        return { left: croquicar.current.offsetWidth / 2 - 30, top: 60 };
      case "2":
        return { left: croquicar.current.offsetWidth / 2 + 55, top: 100 };
      case "3":
        return { left: croquicar.current.offsetWidth / 2 - 110, top: 250 };
      case "4":
        return { left: croquicar.current.offsetWidth / 2 + 55, top: 250 };
      case "5":
        return { left: croquicar.current.offsetWidth / 2 - 110, top: 420 };
      case "6":
        return { left: croquicar.current.offsetWidth / 2 - 30, top: 460 };
      case "7":
        return { left: croquicar.current.offsetWidth / 2 + 55, top: 420 };
      case "8":
        return { left: croquicar.current.offsetWidth / 2 - 30, top: 250 };
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (resultCep?.erro) {
      setSnackBarMessage({
        message: "Esse CEP não existe",
        severity: "error",
      });
    }
  }, [resultCep]);

  const fullSurveySchema = object().shape({
    formValues: object().shape({
      brand: string().required("A marca é necessária"),
      model: string().required("O modelo é necessária"),
      year: string().required("O ano é necessária"),
      color: string().required("A cor é necessária"),
      plate: string()
        .matches(placaRegExp, "Digite uma placa válida.")
        .nullable(true),

      chassis: string().matches(
        chassiRegExp,
        "Digite um número de chassi válido"
      ),
      deliveryCep: string().required("É necessário informar CEP"),
      deliveryNumber: number().required("É necessário informar número"),
    }),
    fotos: object().shape({
      vehiclePics: object().shape({
        vehiclePics: mixed().required("Fotos do veículo são necessárias"),
      }),
      fileDocs: object().shape({
        docVehicle: mixed().required(
          "Foto do documento do veículo é necessária"
        ),
      }),
    }),
    mode: string().notRequired(),
  });

  const handleSubmit = (formData) => {
    fullSurveySchema
      .validate(formData, { abortEarly: false })
      .then(() => createSurvey(formData))
      .catch((error) => {
        setMessage({ message: error.errors[0], show: true, status: 500 });
      });
  };

  // ========================================================================

  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    if (event.target.type === "checkbox") {
      setFormValues((prevValues) => ({ ...prevValues, [name]: checked }));
    } else {
      setFormValues((prevValues) => {
        if (name === "plate" && value === "") {
          return { ...prevValues, [name]: null };
        }

        return { ...prevValues, [name]: value };
      });
    }
  };

  const handleAlert = () => {
    setShowAlert(false);
    setMessage((prev) => {
      return { ...prev, show: false };
    });
  };

  useEffect(() => {
    if (message.message !== "" && message.show) {
      setShowAlert(true);
    }
  }, [message]);

  const [fileDocs, setFileDocs] = useState({});
  const [vehiclePics, setVehiclePics] = useState({});

  const handleInputChange = (name, event) => {
    setFileDocs((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };

  const handleInputChangeVehiclePic = (name, event) => {
    setVehiclePics((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      deliveryStreet: resultCep?.logradouro,
    }));
    setFormValues((prevValues) => ({
      ...prevValues,
      deliveryDistrict: resultCep?.bairro,
    }));
    setFormValues((prevValues) => ({
      ...prevValues,
      deliveryCity: resultCep?.localidade,
    }));
    setFormValues((prevValues) => ({
      ...prevValues,
      deliveryState: resultCep?.uf,
    }));
  }, [resultCep]);

  return (
    <>
      <Helmet>
        <title>Nova Vistoria</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Solicitar Vistoria
          </Typography>
        </Stack>

        <Card className="p-2">
          <Scrollbar>
            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6">Sobre o veículo</Typography>
              <Box className="d-flex mb-1">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Marca"
                      variant="outlined"
                      className="w-100"
                      name="brand"
                      value={formValues.brand || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Modelo"
                      variant="outlined"
                      className="w-100"
                      name="model"
                      value={formValues.model || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Ano"
                      variant="outlined"
                      className="w-100"
                      name="year"
                      value={formValues.year || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Cor"
                      variant="outlined"
                      className="w-100"
                      name="color"
                      value={formValues.color || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Placa"
                      variant="outlined"
                      className="w-100"
                      name="plate"
                      value={formValues.plate || null}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Chassi"
                      variant="outlined"
                      className="w-100"
                      name="chassis"
                      value={formValues.chassis || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Dados Adicionais"
                      variant="outlined"
                      className="w-100"
                      name="additional"
                      value={formValues.additional || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Identificação"
                      variant="outlined"
                      className="w-100"
                      name="cod"
                      value={formValues.cod || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} className="text-center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.armored ?? false}
                          name="armored"
                          onChange={handleChange}
                        />
                      }
                      label="Veículo Blindado"
                    />
                  </Grid>

                  <Grid item md={3} xs={5}>
                    <InputMask
                      mask="99999-999"
                      value={formValues.deliveryCep || ""}
                      onChange={async (event) => {
                        const fieldValue = event.target.value;

                        setFormValues((prevValues) => ({
                          ...prevValues,
                          deliveryCep: fieldValue,
                        }));

                        if (fieldValue.replace(/\D/g, "").length === 8) {
                          await fetchCep(fieldValue.replace(/\D/g, ""));
                        }
                      }}
                      disabled={loadingCep}
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          fullWidth
                          label="CEP"
                          disabled={loadingCep}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item lg={7} md={12} xs={7}>
                    <TextField
                      fullWidth
                      label="Rua"
                      name="deliveryStreet"
                      value={resultCep?.logradouro || ""}
                      InputProps={{
                        endAdornment: loadingCep && (
                          <CircularProgress size={20} />
                        ),
                      }}
                      disabled={loadingCep}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={2} md={12} xs={5}>
                    <TextField
                      fullWidth
                      label="Número"
                      name="deliveryNumber"
                      value={formValues.deliveryNumber || ""}
                      inputProps={{ maxLength: 8 }}
                      InputProps={{
                        endAdornment: loadingCep && (
                          <CircularProgress size={20} />
                        ),
                      }}
                      disabled={loadingCep}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={5} md={12} xs={7}>
                    <TextField
                      fullWidth
                      label="Bairro"
                      name="deliveryDistrict"
                      value={resultCep?.bairro || ""}
                      InputProps={{
                        endAdornment: loadingCep && (
                          <CircularProgress size={20} />
                        ),
                      }}
                      disabled={loadingCep}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={5} md={12} xs={9}>
                    <TextField
                      fullWidth
                      label="Cidade"
                      name="deliveryCity"
                      value={resultCep?.localidade || ""}
                      InputProps={{
                        endAdornment: loadingCep && (
                          <CircularProgress size={20} />
                        ),
                      }}
                      disabled={loadingCep}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={12} xs={3}>
                    <TextField
                      fullWidth
                      label="Estado"
                      name="deliveryState"
                      value={resultCep?.uf || ""}
                      inputProps={{ maxLength: 2 }}
                      InputProps={{
                        endAdornment: loadingCep && (
                          <CircularProgress size={20} />
                        ),
                      }}
                      disabled={loadingCep}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Complemento"
                      name="deliveryComplement"
                      value={formValues.deliveryComplement || ""}
                      InputProps={{
                        endAdornment: loadingCep && (
                          <CircularProgress size={20} />
                        ),
                      }}
                      disabled={loadingCep}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6">Selecione os pontos de danos</Typography>
              <Box
                className="d-column mb-1 text-center croqui-car"
                ref={croquicar}
              >
                <div
                  className="part-selection-layer"
                  ref={partSelectionLayerRef}
                  role="button"
                  style={{
                    // position: 'absolute',
                    // top: '0',
                    // left: '0',
                    // width: '100%',
                    display: "inline-block",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  onClick={handlePartSelection}
                  onKeyDown={handlePartSelection}
                  tabIndex={0}
                >
                  <img width={"300px"} src={croqui} alt="Croqui do Carro" />
                </div>
                {Object.keys(partesDoCarro).map((parte) => {
                  const { left, top } = calcularCoordenadasParte(parte); // Função para calcular as coordenadas de cada parte

                  return (
                    <img
                      width={50}
                      key={parte}
                      className={`part-indicator ${
                        parte === parteSelecionada ? "active" : ""
                      }`}
                      src={point}
                      alt={`Indicador ${parte}`}
                      style={{
                        position: "absolute",
                        left,
                        top,
                        pointerEvents: "none",
                      }}
                    />
                  );
                })}
                {/* <div style={{ marginTop: "10px" }}>
                  Parte selecionada: {parteSelecionada}
                </div> */}
              </Box>
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6">Documentação</Typography>
              <Box className="d-flex mb-1">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 12, sm: 12, md: 12 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <MuiFileInput
                      value={fileDocs.docVehicle || null}
                      onChange={(e) => handleInputChange("docVehicle", e)}
                      placeholder={"Documento do veículo"}
                      multiple
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6">Fotos do veículo</Typography>
              <Box className="d-flex mb-1">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 12, sm: 12, md: 12 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <MuiFileInput
                      multiple
                      value={vehiclePics.vehiclePics || null}
                      onChange={(e) =>
                        handleInputChangeVehiclePic("vehiclePics", e)
                      }
                      placeholder={"Fotos do veículo"}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <LoadingButton
              onClick={() =>
                handleSubmit({
                  formValues,
                  fotos: { fileDocs, vehiclePics },
                  mode: "default",
                })
              }
              className="w-100 mb-1 mt-5"
              loading={isLoading}
              variant="outlined"
            >
              Finalizar
            </LoadingButton>
          </Scrollbar>
        </Card>

        <AlertSnackbar
          openAlert={showAlert}
          duration={2500}
          status={message.status}
          message={message.message}
          onClose={handleAlert}
        />
      </Container>
    </>
  );
}
