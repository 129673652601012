import PLogo from "../img/newImparLogoP.png";

// ----------------------------------------------------------------------

const account = {
  displayName: "Usuário da Silva",
  email: "teste@email.com",
  photoURL: PLogo,
};

export default account;
